import React, {useEffect, useRef, useState} from "react";
import {request} from "./Requests";
import {printError} from "./frontendSpecificFunctions";
import {APICommand} from "./shared-modules/commandApi";

export const EnglishDoneWriting = ({logOut}: {logOut: () => void }) => {

    const clickUntilLogOut = useRef(7)
    const [writing, setWriting] = useState({text: "", color: "grey"})

    useEffect(() => {
        request(APICommand.GetPCCurfew, null).then(e  => {
            const curfew = e.data as Number
            setWriting(curfew > Date.now()
                ? {text: "Lesson is done.", color: "green"}
                : {text: "Do your lesson.", color: "red"})
        })
    }, [])

    const logOutOnFive = async () => {
        if (clickUntilLogOut.current > 1) {
            console.log("logout clicked")
            clickUntilLogOut.current -= 1
        } else {
            try {
                const dto = await request(APICommand.LogUserOut, null)
                console.log("Did logout with response:", dto.message)
                logOut()
            } catch (err) {
                printError("Log out failed")
                setWriting({text: "L o g o u t   e r r o r", color: "red"})
                throw err
            }
        }
    }

    return <span style={{color: writing.color}} onClick={logOutOnFive}>{writing.text}</span>
}



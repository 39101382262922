import {NoPromiseObject, SAFESON} from "./funkcje"

export enum APICommand {
    GetLoggedUser = "get-logged-user",
    LogUserIn = "log-user-in",
    LogUserOut = "log-user-out",
    Log = "log",
    PopLog = "pop-log",
    GetPhoneCurfew = "get-phone-curfew",
    GetPCCurfew = "get-PC-curfew",
    GetResultsOfUsers = "get-results-of-users",
    OpenInternet = "open-internet",
    GetMaterial = "get-material",
    Update = "update",
    GetBackup = "get-backup",
    PostFullBackup = "post-full-backup",
    GetRankList = "get-rank-list",
    GetInstructionsForWatcher = "get-instructions-for-watcher"
}

export type LogLvl = "ERROR" | "NOTICE" | "INFO"


export type  PartialLogRecord = {
    readonly level: LogLvl
    readonly loggerName: string,
    readonly message: string
    readonly screenshotData: string,
}

export type  FullLogRecord = {
    readonly userName: string
    readonly timestamp: number
} & PartialLogRecord


export  type DTOdata = null | boolean | NoPromiseObject | number

export type ResponseDTO<T extends DTOdata> = {
    readonly success: boolean,
    readonly message: string,
    readonly data: T,
}


export function responseDTOwithData<T extends object>(data: T, message: string): ResponseDTO<T> {
    return {
        success: true,
        message,
        data,
    }
}


export type RequestDTO<T extends DTOdata> = {
    readonly command: APICommand,
    readonly userName: string,
    readonly data: T
}


export const commandApiEntryPoint = "/api/"


export async function makeCommandRequest<T extends DTOdata>(serverAddress: string, body: RequestDTO<T>): Promise<ResponseDTO<DTOdata>> {
    const response = await fetch(serverAddress + commandApiEntryPoint, {
        method: "POST",
        body: SAFESON.stringify(body),
        headers: {'Content-Type': "application/json",}
    })
    return parseResponse(response)
}

export async function parseResponse(response: Response): Promise<ResponseDTO<DTOdata>> {
    if (!response.ok) {
        const errMsg = `Response error: ${response.status} - ${response.statusText}`
        console.error(errMsg)
        throw new Error(errMsg)
    } else {
        const respText = await response.clone().text()
        if (respText.length === 0) {
            const message = "response text is 0 long"
            console.error(message)
            throw new Error(message)
        }
        let respDTO: ResponseDTO<DTOdata>
        try {
            respDTO = await response.json()
        } catch (err) {
            console.error(`error while response.json().response.headers is: ${Object.entries(response.headers)}`)
            console.error(`error while response.json().response.body is: ${response.body}`)
            throw err
        }
        if (respDTO.success === undefined || respDTO.message === undefined) {
            throw Error(`Instead response dto some other crap: ${SAFESON.stringify(respDTO).substring(0, 100)}`)
        } else if (!respDTO.success) {
            console.error(respDTO.message)
        }
        return respDTO
    }
}

export const SERVER_URL = "https://lesson-udld.onrender.com"

export function loggingLevels(loggerName: string, userName: string) {
    return {
        info: async (message: string): Promise<void> => await sendLogToServer("INFO", loggerName, userName, message, ""),
        error: async (message: string): Promise<void> => await sendLogToServer("ERROR", loggerName, userName, message, ""),
        notice: async (message: string, base64: string): Promise<void> => await sendLogToServer("NOTICE", loggerName, userName, message, base64),
    }
}


async function sendLogToServer(level: LogLvl, loggerName: string, userName: string, message: string, screenshotData: string): Promise<void> {
    try {
        const dto: RequestDTO<FullLogRecord> = {
            command: APICommand.Log,
            userName,
            data: {
                userName,
                timestamp: Date.now(),
                loggerName,
                level,
                message,
                screenshotData,
            }
        }
        await makeCommandRequest(SERVER_URL, dto)
    } catch (err) {
        console.log("Writing to local error log:", message)
    }

}

